<template>
  <div>
    <headerv-2></headerv-2>
    <div class="flex items-center min-h-screen p-6 bg-gray-50 dark:bg-gray-900">
    <div class="flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-xl dark:bg-gray-800">
      <div class="flex flex-col overflow-y-auto md:flex-row">
        <div class="h-32 md:h-auto md:w-1/2">
          <img
            aria-hidden="true"
            class="object-cover w-full h-full dark:hidden"
            src="/images/banner.jpg"
            alt="login"
          />
          <img
            aria-hidden="true"
            class="hidden object-cover w-full h-full dark:block"
            src="/images/banner.jpg"
            alt="Office"
          />
        </div>
        <div class="flex items-center justify-center p-6 sm:p-12 md:w-1/2">
          <div class="w-full">
            <h1 class="mb-4 text-xl font-semibold text-gray-700 dark:text-gray-200">
              Login
            </h1>
            <form @submit.prevent="handleSubmit">
              <label class="block text-sm">
                <span class="text-gray-700 dark:text-gray-400">Email</span>
                <input
                  v-bind:class="['border p-3 rounded outline-none block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input', { 'border-red-500': errors.email }]"
                  placeholder="user@gmail.com"
                  type="email"
                  name="email"
                  v-model="formData.email"
                />
                <p v-if="errors.email" class="text-red-500 text-xs mt-1">{{ errors.email }}</p>
              </label>
              <label class="block mt-4 text-sm">
                <span class="text-gray-700 dark:text-gray-400">Password</span>
                <input
                  v-bind:class="['border p-3 rounded outline-none block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input', { 'border-red-500': errors.password }]"
                  placeholder="***************"
                  type="password"
                  name="password"
                  v-model="formData.password"
                />
                <p v-if="errors.password" class="text-red-500 text-xs mt-1">{{ errors.password }}</p>
              </label>
              <button
                type="submit"
                class="flex items-center justify-center gap-2 w-full px-4 py-2 mt-4 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-red-600 border border-transparent rounded-lg active:bg-red-500 hover:bg-black focus:outline-none focus:shadow-outline-purple"
              >
                Log In
                <template v-if="loading">
                  <loaderVue />
                </template>
              </button>
            </form>
            <hr class="my-2" />
            <p class="mt-4">
              <a class="text-sm font-medium text-red-500 hover:underline hover:text-black" href="/forgot-password">
                Forgot your password?
              </a>
            </p>
            <p class="mt-1">
              <a class="text-sm font-medium text-red-500 hover:underline hover:text-black" href="/sign-up">
               New to Dial A lawyer? Create account
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
     
    <footerv-2></footerv-2>
  </div>
</template>

<script>
import Headerv2 from '../v2/Headerv2.vue';
import Footerv2 from '../v2/Footerv2.vue';
import loaderVue from '../components/loader.vue';
export default {
  components: { Headerv2, Footerv2,loaderVue },
  data() {
    return {
      title: 'Account',
      description: 'Our mission is to aid access to justice across Africa by linking verified legal professionals in the justice sector to the market',
      url: 'https://dialalawyer.africa',
      image: 'https://dialalawyer.africa/images/banner.jpg',
      // Binded model property for "Sign In Form" switch button for "Remember Me" .
      rememberMe: true,
      formData: {
        email: '',
        password: '',
      },
      errors: {
        email: null,
        password: null,
      },
      loading: false,

    };
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
      { name: 'description', content: 'Our mission is to aid access to justice across Africa by linking verified legal professionals in the justice sector to the market' },
  // Open Graph (for Facebook, LinkedIn, etc.)
  { property: 'og:title', content: this.title },
        { property: 'og:description', content: this.description },
        { property: 'og:image', content: this.image },
        { property: 'og:url', content: this.url },

        // Twitter
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: this.title },
        { name: 'twitter:description', content: this.description },
        { name: 'twitter:image', content: this.image },
        { name: 'twitter:url', content: this.url },
        // Add more tags as needed
      ]
    }
  },
  beforeCreate() {
    // Creates the form and adds to it component's "form" property.
    this.form = this.$form.createForm(this, { name: "normal_login" });
  },
  methods: {
    // Handles input validation after submission.
    validateForm() {
      const errors = {};
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (!this.formData.email) {
        errors.email = 'Email is required.';
      } else if (!emailPattern.test(this.formData.email)) {
        errors.email = 'Invalid email address.';
      }

      if (!this.formData.password) {
        errors.password = 'Password is required.';
      } else if (this.formData.password.length < 6) {
        errors.password = 'Password must be at least 6 characters long.';
      }

      this.errors = errors;
      return Object.keys(errors).length === 0;
    },
    handleChange(event) {
      const { name, value } = event.target;
      this.formData[name] = value;
    },
    async handleSubmit() {
      if (!this.validateForm()) {
        return;
      }

      this.loading = true;

      try {
        // Handle form submission
        await this.$store.dispatch("login", this.formData);;
      } catch (error) {
        this.errors = error.response.data.errors || {};
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
body {
  background-color: #ffffff;
}
.sign-img {
  background-image: url("../../public/images/bg-signin.png");
  height: 80vh;
  background-size: cover;
}
</style>
